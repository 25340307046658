@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Source+Code+Pro:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display+SC&display=swap');

@layer base {
    body {
        @apply font-[SourceCodePro];
    }
    li {
        @apply px-4;
        @apply cursor-pointer;
    }
}

.background-color {
    background-color: #EDEAE3;
}

.text-color {
    color: #4C433D;
}
